.pagination li a{
	line-height: 22px;
}

.pagination li a .small{
	font-size:1.2rem;	
	line-height: 28px;	
}

.pagination li a i{
	opacity: 0.6;
}

.pagination li {
	display: inline-flex;
	place-items: center;
}