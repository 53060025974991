.nav{
	float:none;
	clear: both;
	font-weight: normal;
	margin-top: 0.2rem;
	margin-bottom: 0.2rem;
	position: relative;
	//border-bottom: 1px solid rgba(0, 0, 0, 0.25);
}

nav {
	margin-right: 30px;
}

nav .shop{
	font-weight: bold;
	color: green;
}

.header{
	padding:0.75rem 0 0 0 ;
	position: relative;
}

.btn-chef99{
    border-radius: 0 0 2px 2px;
    font-size: 1rem;
    font-weight: normal;
    padding: 0 0.75rem;	
    margin-left:5px;
    margin-bottom:20px;
}

.social{
	clear: right;
}

.user-btn{
	margin-left:10px;
}

.user-btn i{
	//color: color('light-blue','lighten-5');
	font-size: 30px;
}

#popover-user{
	width: 340px;
	font-weight: normal;
	margin-top:5px;
}

.user-img{
    height: 40px;    
    width: 40px;
}

.user-small {
	font-size: 1rem;
}

.mdi.mdi-settings.settings{
	font-size: 1.4rem;	
	color: color('blue-grey','darken-2');
}

.mdi.mdi-settings.settings:HOVER{
	color: color('blue-grey','darken-4');
}

@media only screen and (min-width : 1026px) {
  	.btn-chef99-wrapper{
		width: auto;
		max-width: none;
	}
	.social{
		clear: none;
	}
	.nav{
		float: right;
		clear: right;
		border:none;
	}
}

.mobile-bars{
	display: none;
}

@media #{$small-and-down} {
	.nav, .btn-chef99-wrapper, .social{
		display: none;
	}
	
	#mobile-nav{
		font-family: 'Roboto', sans-serif;
	}
	
	.mobile-bars{
	    cursor: pointer;
	    display: block;
	    font-size: 2.5rem;
	}
	
	.mobile-social  a{
		background-color: #26a69a;
	    border-radius: 50%;
	    color: #ffffff;
	    cursor: pointer;
	    display: inline-block;
	    height: 42px;
	    line-height: 47px;
	    overflow: hidden;
	    padding: 0;
	    position: relative;
	    transition: all 0.3s ease 0s;
	    vertical-align: middle;
	    width: 42px;
	    z-index: 1;
	    margin-top:0 !important;
	}
}