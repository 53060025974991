.absolute{
	position: absolute;
}

.relative{
	position: relative;
}

.card-comments{
	//background: #f4f4f4;
	list-style: none;
	margin: 0;
	padding:0;
}

.card-comment{
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 20px;
    position: relative;
}

.comment-wrapper{
	display: block;
	padding-left:70px;
}

.card-comment .user{
	display:inline-block;
	font-weight: bold;   
}

.card-comment .user-link{
	display:inline-block;
	font-weight: 200;   
}

.card-comment .user-link::before{
	content: ' - '
}

.card-comment .user-img{
	display: inline-block;
}

.card-comment .user-img img{
	border-radius: 50%;
	width: 50px;
	height:50px;	
	margin-right: 20px;
}

.card-comment .date{
	float:right;
}

.card-comment .comment{
	//font-weight: normal;
}

.card-comment-form{
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    padding: 20px;
    position: relative;	
}

.card-comment-form h3{
	margin:0;
	font-family: "Roboto",sans-serif;
	font-weight: 300;
}

.login-social{
	margin-top:5px;
}

.shares-buttons-wrapper{
	display: inline-block;
	word-spacing:5px;
}

.comments-button-wrapper .btn, .shares-button-wrapper .btn {
	padding:0 1.2rem;
}

.shares-counter{
    border-radius: 50%;
    color: #ffffff;
    font-size: 11px;
    font-weight: normal;
    height: 25px;
    line-height: 22px;
    position: absolute;
    right: -12px;
    top: -12px;
    width: 25px;
    z-index: 1;	
    text-align: center;
}

.shares-counter i{
	line-height: 25px;	
}

.sm-share-button-wrapper{
	display: inline;
	word-spacing:5px;
}

.sm-share-button-wrapper ul, .sm-share-button-wrapper li{
	display: inline-block;
}

.sm-share-button-wrapper ul, .shares-buttons-wrapper ul{
	padding:0;
	margin:0;
}

.fixed-action-btn{
	bottom: auto;
}

.shares-button-wrapper{
	position: relative;	
    display: inline-block;
}


.comments-button-wrapper{
	position: relative;	
    margin-left: 20px;	
}


.banner{
	position: relative;
    background-color: #fff;
	color: #000;
	font-weight: bold;
    transition: box-shadow .25s;
    border-radius: 2px;		
	margin-top:0.5rem;
	box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
	background-position-y: -75%;
}

.banner > .logo {
	font-size:2rem;
	font-family: 'Roboto';
	padding:0.5rem;
	border-radius: 2px;
	color: #4CAF50;
	text-align: center;
	height:50px;
	position: relative;
	margin-top:3px;
}

.banner > .logo > img {
	height:100%;
}

.banner > .text {
	font-size:1.0rem;
	padding:0.5rem;
	border-radius: 2px;
	background: #255574;
	color:#fff;
	text-align: center;
}

.banner > .overlay {
	
}

.banner > .content {
	display:grid;
	grid-template-columns: 1fr 1fr minmax(200px, 2fr) 1fr 1fr;
}

.banner > .content > .logo-wrapper{
	padding:0;
	display: flex;
	justify-content: center;	
}

.banner > .content > .logo-wrapper > .logo{
	height: 100%;
	width: 100%;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
}

.banner > .content > .product{
	position: relative;
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
	height: 100px;
	margin: 5px;
}

.banner > .content > .product > .price{ 
	position: absolute;
	right: 0;
	bottom: 0;
	padding:3px;
	background: #263238;
	color: #fff;
    border-radius: 2px;
}

@media #{$small-and-down} {
	.banner > .content > .hide-mobile {
		display:none;
	}
	.banner > .content {
		grid-template-columns: 1fr 160px 1fr;
	}

}