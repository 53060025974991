#search-label{
	position: absolute;
	top:0;
	right: 0;
	z-index: 2;
	font-size: 1.5rem;
	line-height: 64px;
	padding: 0 10px;
	cursor: pointer;
	color:rgba(0, 0, 0, 0.75);
	transition: color 0.3s;
}

#search-label:HOVER{
	color:#000;
}

#search-field:FOCUS + #search-field-wrapper + #search-submit + #search-label{
	display: none;
}

#search-submit{
	display: none;
	position: absolute;
	top:0;
	right: 0;
	z-index: 3;
	font-size: 1.5rem;
	line-height: 64px;
	padding: 0 10px;
	cursor: pointer;
	color:rgba(0, 0, 0, 0.75);
	border: none;
	background: none;
}

#search-submit:HOVER{
	display: block;
	color:#000;
}

#search-field:FOCUS + #search-field-wrapper + #search-submit{
	display: block;
}

#search-field{	
	position: absolute;	
	border: 1px solid rgba(0, 0, 0, 0);	
	box-sizing: border-box;	
	z-index: 2;
	top:10px;
	right:0;
	width: 1px;
	height: 45px;
	transition: width 0.3s, border-color 0.4s, padding 0.5s;
	display: block;
	box-sizing: border-box;
	background: #fff;
}

#search-field:FOCUS {
	transition: width 0.5s, border-color 0.1s;
	width: 100%;
	padding:0  40px 0 15px;
	border: 1px solid rgba(0, 0, 0, 0.25);
	box-shadow:none;
	outline:none;
}

#search-field:FOCUS + #search-field-wrapper {
	background: rgba(0,0,0,0);
	position: absolute;
	top:0;
	left: 0;
	bottom:0;
	right:0;
	z-index: 1;
}

#mobile-search-form{
	width: 100%;
	height: 45px;
	border: 1px solid rgba(0,0,0,0.25);
	position: relative;
	clear: both;
	padding:0;
	margin: 0;
}

#mobile-search-submit{
	position: absolute;
	top:0;
	right: 0;
	z-index: 3;
	font-size: 2rem;
	line-height: 45px;
	padding: 0 10px;
	cursor: pointer;
	color:rgba(0, 0, 0, 0.75);
	border: none;
	background: none;
}

#mobile-search-field{
	border:none;
	background: none;
	width: 100%;
	height: 100%;
	padding:0 15px;
	box-sizing:border-box;
}

#mobile-search-field:FOCUS{
	border-bottom:none;
	outline: none;
	box-shadow:none;
}

@media #{$medium-and-up} {
	#mobile-search-form{
		display: none;
	}
}