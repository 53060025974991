.register-form.card  .card-title{
	font-family: "Roboto",sans-serif;
	font-weight: 200;
}

.register-form .btn{
	font-weight:normal;
}
.register-form .btn i{
	margin-left:15px;
}

.card-message{
	font-weight: normal;
}