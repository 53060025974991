.products .card {
    text-align: center;
    min-height: 330px;
    display: flex;
    flex-direction: column;
}

.products .card-title{
	margin:0;
	padding:0;
	font-family: roboto;
	font-size:14px;
	display: inline;
	//color:#fff;
}

.products .card .card-image .card-title{
	//top:0;
	//bottom:auto;
}
.products .card .card-image{
	padding: 5px 5px;
    display: flex;
    flex-grow: 1;
    place-content: center;
    place-items: center;
}

.products .card .card-image img{
	//width: auto;
	display: inline;
	max-height: 145px;
    width: auto;
}

.products .card-action{
	padding:0;
	vertical-align: middle;
	color: #fff;
	background: color('blue','base');
	border-top: none;
	display: -webkit-flex; /* Safari */
  	display: flex;
  	-webkit-flex-direction: row; /* Safari */
  	flex-direction:         row;
}

.products .card-price{
	padding: 10px 20px;
	background: color('green','base');	
	font-weight: bold;
	height: 100%;
}

.products .card-shop{
	display: block;
	padding: 10px 20px;
	height: 100%;
	font-weight: bold;
	white-space: nowrap;
  	overflow: hidden;
  	text-overflow: ellipsis;
  	text-align: left;
  	flex-grow:1;
  	-webkit-flex-grow:1;
}

.products .card-sponsor{
    font-size: 15px;
	font-weight: bold;
    padding: 10px;
    right: 0;
    top: 0;
    z-index: 1;
	background: #255574;
	color:#fff;
	margin-bottom:5px;
}

.products .card .card-content{
	padding: 10px 20px;
}

.products a{
	color:rgba(0, 0, 0, 0.87);
	outline: 0;
}

.products .title {
    position: relative;
    background-color: #4CAF50;
	color: #fff;
	font-weight: bold;
    transition: box-shadow .25s;
    border-radius: 2px;	
	padding:0.5rem;
}

.products .card-slogan{
	background: #255574;
	color:#fff;
	padding:6px;
	font-weight: bold;
}

#bloglovin{
	margin-top:20px;
}