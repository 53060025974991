html{
	font-family: 'Open Sans', sans-serif;
	font-weight:300;
}

a {
	outline: none;	
}

h1, h2, h3, h4, h5{
	font-family:'Lobster';	
	color: #255574;	
	margin-bottom: 0;
}

h2{
	font-size: 22px;	
}

h3{
	font-size: 20px;	
}

h4{
	font-size: 18px;	
}

h5{
	font-size: 16px;		
}

.mobile-text{
	font-size: 12px !important; 
}

@media #{$large-and-up} {
  .container {
    width: 80%;
  }
}

img{
	max-width:100%;
}

.card{
	overflow:hidden;
}

.card .card-title{
	font-family:'Lobster';
	font-weight: normal;
	display:block;
	color: #255574;	
	margin: 0;	
}

.card .card-content .card-title{
	line-height: normal;
}

.card .card-image .card-title{
	text-shadow:0 0 3px #000;
}


.card .card-content ul{
	margin: 0;
}

.card a .card-content {
    color: rgba(0,0,0,.87);
}

.card.page-title{
	height: 120px;
	overflow: hidden;
}

.card.page-title .card-image{
	height: 120px;
	position: relative;
}

.card.page-title .card-image img{
	min-height: 120px;
	min-width: 100%;
	max-width:none; 
	width: auto;
	float:right;	
}

.login-form-mobile li{
	line-height: 1.5;
}

.input-field-mobile{
	line-height: 1.5;
	margin: 10px 15px;
}

.input-field-mobile button{
	width: 100%;
}

.mdi.mdi-settings.settings-mobile{
	font-size: 1.4rem;	
	color: color('blue-grey','darken-2');
	margin:0 !important;
}

.card .card-content p {
	margin-bottom:20px;
}

@media only screen and (max-width: 992px){
	.sidenav li > a {
    	padding: 0 16px;
	}
}

.mobile-social{
	display: flex;
	justify-content: space-around;	
}

.login-social-button{
	padding: 0 .75rem;
	margin-top: 1rem;
}

.clear{
	clear: both;
}

.input-error{
	position: relative;
    min-height: 18px;
    display: block;
    font-size: 12px;
	color:color('red','base') !important;
}

.input-error.checkbox{
	margin:0 0 0 35px;
}

[type="checkbox"].invalid:not(:checked) + span:not(.lever):before, [type="checkbox"]:not(.filled-in).invalid:not(:checked) + span:not(.lever):after{
	border-color:color('red','base'); 
}

.card-play-icon {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -webkit-align-items: center;
    -webkit-justify-content: center;
    place-items: center;
}

.card-play-icon i {
    color: #fff;
    font-size: 2.5rem;
    text-shadow: 0 0 3px #000;
}

.card-play-icon.large i {
    color: #fff;
    font-size: 4rem;
    text-shadow: 0 0 3px #000;
}

.youtube-wrapper {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
}

.youtube-wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}