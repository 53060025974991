.profile-form.card  .card-title{
	font-family: "Roboto",sans-serif;
	font-weight: 200;
}

.profile-form .btn{
	font-weight:normal;
}
.profile-form .btn i{
	margin-left:15px;
}

.profile-form [type="radio"] + label::before, [type="radio"] + label::after  {
    display:none;
}

.profile-form [type="radio"]:not(:checked) + label, [type="radio"]:checked + label {
	height:auto;
	max-width: 100px;
	border-radius:50%;
	padding:0;	
	overflow: hidden;
	display: inline-block;
	box-sizing:border-box;
	margin: 5px;
	line-height: 0;
}

.profile-form [type="radio"]:checked + label{
	border:5px solid color('green','base');
}

.profile-form .avatars{
	margin: -5px 0;
}

.profile-form .avatar{
	float: left;
	width: (100% / 7);	
	text-align: center;
}


.card-message{
	font-weight: normal;
}

.hidden{
	display: none;
}